.account-info-container {
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    align-items: center;
    text-align: left;
    border-radius: 20px;
    box-shadow: 0 20px 25px -15px $boxshadowgray;
    background-color: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
