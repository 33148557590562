.portfolio-listing-container {
    margin: 50px;
    // border-radius: 20px;
    // box-shadow: 0 20px 25px -15px $boxshadowgray;
    // border: solid 1px $bordergrey;
    // background-color: $bggrey;
    padding: 20px;
    .container-title {
        margin-bottom: 25px;
        padding-left: 15px;
    }
}
