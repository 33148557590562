.funds-tab-container {
    .funds-type-tab .nav-item {
        margin-top: 0.25em;
        color: $grey;
        font-size: 24px;
        // margin: 10px 10px 0;
        margin: 10px 0 0;
        padding: 20px 30px;
        position: relative;
        &.active {
            border: none;
            background-color: transparent;
            font-weight: 600;
            color: $purple;
        }
        .tab-title {
            line-height: 26px;
        }
        .tab-logo {
            width: 30px;
            margin-right: 10px;
        }
        .coming-soon-tag {
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: 1;
            overflow: hidden;
            width: 75px;
            height: 75px;
            text-align: right;
        }
        .coming-soon-tag span {
            font-size: 10px;
            color: #fff;
            text-align: center;
            line-height: 15px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 100px;
            display: block;
            background: $purple;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px;
            right: -21px;
        }
        .coming-soon-tag span::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 100%;
            z-index: -1;
            border-left: 5px solid $purple;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-top: 5px solid $purple;
        }
        .coming-soon-tag span::after {
            content: '';
            position: absolute;
            right: 0px;
            top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid $purple;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $purple;
        }
    }
    .heading-row {
        margin: 20px 0;
        text-align: left;
        color: $grey;
        padding: 0 20px;
    }
    .funds-list-group {
        // padding: 0 1.5em;
        padding: 0;
        background-color: transparent;
        margin-top: 20px;
        .sort-button-container {
            background-color: transparent;
            padding: 0px 20px;
            border: 0;
            color: $grey;
            font-size: small;

            svg {
                color: $purple;
            }
        }
    }
    .no-funds-container {
        margin: 20px auto;
        border: none;
    }
    .funds-listing-row .funds-listing-col {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @media #{$ipadLandscape, $ipadPortrait, $mobile} {
        .funds-type-tab .nav-item {
            font-size: 20px;
            padding: 5px 10px;
        }
        .funds-list-group {
            .sort-button-container {
                display: none;
            }
        }
    }
}
