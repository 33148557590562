// .portfolio-page {
// 	.chart-wrapper, .investment-wrapper {
// 		border-radius: 20px;
// 		box-shadow: 0 20px 25px -15px $boxshadowgray;
// 		border: solid 1px $bordergrey;
// 		padding: 20px;
// 		background-color: $bggrey;
// 	}
// 	.investment-wrapper {
// 		background-color: $bggrey;
// 		.minimum-amt-text {
// 			margin-bottom: 10px;
// 		}
// 		.minimum-amt-value {
// 			margin-bottom: 10px;
// 		}
// 	}
// }

// .portfolio-amt {
// 	margin: 1em;
// 	margin-top: 2em;
// 	padding: 0 1em;
// 	background-color: $bggrey;
// }

// .defi-buy {
// 	margin: 1em;
// 	margin-top: 2em;
// 	padding: 0 1em;
// }

.portfolio-page-container {
	padding: 20px 0;
	.information-column {
		.title-info-row {
			display: flex;
			// border-bottom: solid 1px $bordergrey;
			.icon {
				max-width: 64px;
				max-height: 64px;
				margin: 10px;
				background-color: $white;
			}
			.title-block {
				margin: 10px;
			}
		}
		.general-info-row {
			// border-bottom: solid 1px $bordergrey;
			padding: 20px 0;
			.subtext {
				font-size: 12px;
				color: $grey;
			}
			.creator-info-column {
				display: flex;
				align-items: center;
				.creator-icon {
					width: 36px;
					height: 36px;
					margin-right: 10px;
				}
				.creator-name {
					font-size: 16px;
				}
			}
			.market-info-column {
				.market-cap {
					font-size: 16px;
				}
			}

		}
		.information-row {
			padding: 20px;
			flex-direction: column;
			.title {
				margin-bottom: 15px;
			}
			.pdf-link {
				color: $darkgrey;
				text-decoration: underline;
				font-weight: bold;
				&:hover {
					color: $purple;
				}
			}
		}
	}
	.transaction-column {
		padding: 10px 0;
		max-width: 450px;
		.fixed-column {
			// position: fixed;
			min-width: 200px;
			.wallet-info {
				margin-bottom: 30px;
				.wallet-info-block .list-group-item {
					border: none;
					padding: 0.75em 0;
					display: flex;
					align-items: baseline;
					background-color: $black;
					p {
						font-weight: 700;
						font-size: 16px;
						margin: 0;
						&.info-value {
							margin-left: 10px;
							font-size: 18px;
						}
					}
				}
			}
			.defi-buy .fund-action-list-group {
				// padding: 1em;
				padding: 0;
				.list-group-item {
					padding: 0.75em 0;
					input {
						border: solid 0.5px $boxshadowgray;
						border-radius: 10px;
					}
				}
			}
			.transaction-breakup-block {
				background-color: $bggrey;
				border-radius: 10px;
				padding: 10px 0;
				border: solid 0.5px $boxshadowgray;
				.list-group-item {
					display: flex;
					justify-content: space-between;
					background-color: transparent;
					border: 0;
					font-weight: 300;
					padding: 0.25rem .75rem;
					p {
						font-weight: 600;
					}
				}
			}
		}
	}
} 
