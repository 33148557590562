.portfolio-link {
    display: flex;
    background-color: $purple;
    justify-content: space-between;
    border-radius: 30px;
    align-items: center;
    padding: 15px 25px;
    margin: 10px auto;
    width: 350px;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-decoration: none;
    color: $white;
    box-sizing: border-box;
    &.image-link {
        padding: 10px 17px;
    }
    .portfolio-icon {
        width: 38px;
        height: 38px;
        border-radius: 30px;
    }
}
