.exchange-page,
.transaction-page,
.login-page,
.signup-page,
.create-page {
    .row {
        justify-content: center;
    }
}
.login-page,
.signup-page {
    .row .col-md-8 {
        margin: 50px auto;
        padding: 20px;
        background-color: $bggrey;
        border-radius: 20px;
    }
}
.defi-exchange-page {
    .row {
        height: 100%;
        .col {
            margin: auto;
            .connect-btn {
                margin: auto;
                background-color: $purple;
                padding: 1em 2em;
                border-radius: 10px;
                align-items: center;
                font-size: 24px;
                font-weight: 500;
                span {
                    margin-left: 10px;
                }
            }
        }
    }
    @media #{$ipadPortrait, $mobile} {
        .row .col {
            .connect-btn {
                font-size: 20px;
                padding: 15px 25px;
            }
        }
    }
}
.switch-modal {
    .login-container,
    .signup-form-container {
        margin: 50px 50px 20px;
        padding: 0;
    }
    .link-container {
        padding: 20px 50px;
        display: flex;
        align-items: baseline;
        justify-content: center;
        color: $darkgrey;
        .title {
            margin: 0 20px 0 0;
        }
        .link-btn {
            text-decoration: none;
            background-color: $white;
            margin: 0;
            color: $darkgrey;
            &:hover {
                color: $grey;
            }
        }
    }
}
.reset-password-container {
    .row {
        justify-content: center;
        form {
            margin-top: 20px;
        }
    }
}
.landing-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    width: 100%;
    background-size: cover;
    background-position: bottom;
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.3;
    }
    .header-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
		padding: 20px;
        text-align: center;
        max-width: 850px;
        z-index: 10;
        color: $white;
        .app-logo {
            max-width: 130px;
            border-radius: 100px;
            margin-bottom: 15px;
            border-radius: 50%;
            box-shadow:
                0 0 15px $lightpink,
                0 0 15px $darkpuple;
        }
    }
	.type-block {
		text-align: center;
		margin: 30px auto;
		padding: 20px;
		border-radius: 20px;
		box-shadow: 0 20px 25px -15px $boxshadowgray;
        z-index: 10;
        color: $white;
	}
	.link-wrapper {
		display: flex;
		justify-content: space-evenly;
		// margin-top: 50px;
		.finance-btn {
			background-color: $purple;
			padding: 1em 2em;
			border-radius: 10px;
			align-items: center;
			font-size: 24px;
			font-weight: 500;
			width: 350px;
			margin: 20px;
			color: $white;
			text-align: center;
			text-decoration: none;
			&:hover {
				opacity: 0.9;
			}
		}
		@media #{$mobile} {
			.finance-btn {
				font-size: 18px;
				width: 250px;
			}
		}
	}
    // new landing page blocks
    .how-to-use-container {
        .how-to-use-header {
            padding: 50px;
        }
    }
    .landing-footer-container {
        margin: 10px auto;
        text-align: center;
        .connect-header, .connect-link {
            font-family: 'Prompt Regular';
            font-size: 40px;
            line-height: 60px;
            margin-bottom: 40px;
        }
        .connect-wrapper {
            display: flex;
            justify-content: space-between;
            .connect-link {
                color: #014FFF;
                margin: 0 30px;
            }
            .twitter-icon {
                fill: #1DA1F2;
                stroke: #1DA1F2;
            }
        }
        @media #{$mobile} {
			.connect-wrapper{
				display: flex;
                flex-direction: column;
			}
		}
    }
}
