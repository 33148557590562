.transaction-status-container {
    width: 60vw;
    margin: 50px auto;
    h2 {
        text-align: center;
    }
    .transaction-block-wrapper {
        background: white;
        padding: 30px 20px;
        text-align: center;
        margin: 30px auto;
        border-radius: 30px;
        .status-block {
            text-align: center;
            margin-bottom: 20px;
            h1,
            h4 {
                margin-bottom: 20px;
                color: $darkgrey;
            }
            .transaction-amount {
                text-align: center;
                padding: 17px 29px;
                border: none;
                font-size: 18px;
                line-height: 18px;
                border: solid 1px $grey;
                border-radius: 30px;
                width: 70%;
                margin: 0 auto 20px;
                color: $darkgrey;
                box-shadow: 0 20px 25px -15px rgba(104, 100, 100, 0.2);
            }
        }
        .back-btn {
            margin: 0 auto;
            cursor: pointer;
            max-width: 300px;
        }
    }
}
