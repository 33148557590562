.signup-container {
    padding: 20px 50px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    color: $darkgrey;
    .signup-title {
        margin: 0 20px 0 0;
    }
    .signup-btn {
        text-decoration: none;
        background-color: $white;
        margin: 0;
        color: $darkgrey;
        &:hover {
            color: $grey;
        }
    }
    @media #{$ipadPortrait, $mobile} {
        flex-direction: column;
        align-items: center;
        padding: 20px 30px;
        .signup-title {
            margin-bottom: 20px;
        }
    }
}
