.footer-container {
    background-color: $black;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    .footer-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        margin: 0;
        color: $white;
    }
    .footer-links {
        display: flex;
        align-items: flex-end;
        .footer-text {
            text-decoration: none;
        }
    }
    .privacy-link {
        padding-right: 20px;
        border-right: solid 2px $darkgrey;
    }
    .status-link {
        padding-left: 20px;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        padding: 30px 40px;
        .footer-links {
            margin-top: 20px;
        }
    }
}
