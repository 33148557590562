.switch-menu-container {
    margin: 50px auto;
    max-width: 40vw;
    .menu-items {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
        .switch-btn {
            cursor: pointer;
        }
        .not-selected {
            background-color: $grey;
        }
    }
    .portfolio-list {
        &.not-selected {
            display: none;
        }
    }
}
