.create-index-container {
    .header-row {
        margin-bottom: 20px;
        margin-top: 10px;
        .header-col {
            display: flex;
            .coin-logo {
                background-color: $white;
                margin-left: 30px;
                width: 64px;
                height: 64px;
                border-radius: 50px;
                box-shadow:
                    0 0 15px $lightpink,
                    0 0 15px $darkpuple;
            }
        }
    }
    .basket-create-container {
        .coin-selector {
            margin-top: 0.25em;
            color: $grey;
            font-size: 24px;
        }
        .coin-allocation-container.hide {
            display: none;
        }
    }

    .portfolio-page {
        .chart-wrapper,
        .investment-wrapper {
            border-radius: 20px;
            box-shadow: 0 20px 25px -15px $boxshadowgray;
            border: solid 1px $bordergrey;
            padding: 20px;
            background-color: $bggrey;
        }
        .investment-wrapper {
            background-color: $bggrey;
            .minimum-amt-text {
                margin-bottom: 10px;
            }
            .minimum-amt-value {
                margin-bottom: 10px;
            }
        }
    }

    .portfolio-amt {
        margin: 1em;
        margin-top: 2em;
        padding: 0 1em;
        background-color: $bggrey;
    }

    .fund-action-list-group {
        padding: 0 1em;
        background-color: transparent;

        .fund-action-container {
            background: $white;
            padding: 1em;
            margin: 0.5em 0;
            border-radius: 10px;
            align-items: center;
            text-align: center;
            font-size: 24px;
            color: $purple;
            box-shadow: 0 20px 25px -15px $boxshadowgray;
        }

        .accent {
            background-color: $purple;
            color: white;
        }
    }
}
