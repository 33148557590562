.coin-selection-container {
    margin: 0px 15px 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow:
        0 0 15px $lightpink,
        0 0 15px $darkpuple;
    background-color: $black;
    .container-title {
        margin: 0px 10px 20px;
    }
    .Basket-form {
        .form-group {
            margin-bottom: 20px;
        }
        .file-uploader-block {
            width: 100%;
            padding: 15px 28px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            background-color: $green;
            color: $white;
            box-shadow: 0 20px 25px -15px $boxshadowgray;
            cursor: pointer;
            font-family: Prompt;
            border-radius: 30px;
            text-align: center;
            .upload-logo {
                position: absolute;
                opacity: 0;
                z-index: 10;
                left: 0;
                width: 100%;
                cursor: pointer;
            }
        }
        .file-upload-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 30px 20px 27px;
            border-radius: 30px;
            background-color: $white;
            font-size: 16px;
            line-height: 24px;
            color: $darkgrey;
            .icon-filename {
                max-width: 550px;
            }
            .remove-file {
                padding: 0;
                margin: 0 5px;
                min-width: 0;
                border-radius: 25px;
                border: solid 1px $white;
                .delete-icon {
                    border-radius: 25px;
                    width: 40px;
                }
            }
        }

        .button-wrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 30px;
            .cancel-btn {
                background-color: $white;
                color: $darkgrey;
            }
        }
    }
}
