@font-face {
    font-family: 'Prompt';
    src: url('../fonts/Prompt-Bold.ttf');
}

@font-face {
    font-family: 'Prompt Regular';
    src: url('../fonts/Prompt-Regular.ttf');
}

@font-face {
    font-family: 'DMSans';
    src: url('../fonts/DMSans-Regular.ttf');
}