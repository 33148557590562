.navigation-menu-container {
	border-right: solid 1px $bordergrey;
	.navbar {
		justify-content: space-between;
		align-items: normal;
		padding-right: 0;
		background-color: $black;
		.navbar-collapse {
			margin-top: 25%;
			.header-container {
				display: none;
			}
		}
		.navbar-nav {
			display: flex;
			flex-direction: column;
			text-align: left;
			width: 100%;
			margin-left: 0.5rem;
		}
		.nav-link {
			font-family: Prompt;
			color: $darkgrey;
			padding: 10px 0;
			margin: 10px 0;
			text-decoration: none;
			font-size: 20px;
			line-height: 20px;
			color: darkgrey;
			display: flex;
			justify-content: left;
			align-items: center;
			&:hover, &.active {
				color: black;
				border-right: solid $purple;
				&>svg {
					color: $purple;
				}
			}
			&>span {
				margin-left: 10px;
			}
		}
		.logout-btn {
			background-color: transparent;
			font-weight: 500;
			box-shadow: none;
		}
		.create-basket-btn {
			background-color: $purple;
			font-weight: 500;
			color: white;
			box-shadow: 0 20px 25px 0px $boxshadowgray;
		}
	}
	.brand-wrapper {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $darkgrey;
		margin: 0;
		.app-logo {
			width: 70px;
			height: 70px;
			border-radius: 50px;
			box-shadow: 0 0 10px #B779FD, 0 0 10px #511397;
		}
		.bb-title {
			font-weight: 600;
			text-align: center;
			margin-left: 12px;
			font-size: 38px;
		}
		.beta-disclaimer {
			font-size: 12px;
			text-transform: uppercase;
			font-style: italic;
			margin-left: 12px;
			font-weight: 600;
		}
	}
	@media #{$ipadPortrait, $mobile} {
		border-right: none;
		max-width: unset;
		width: unset;
		display: flex;
		.navbar {
			height: unset;
			flex-direction: row;
			width: 100%;
			.navbar-toggler {
				margin-right: 10px;
			}
			.navbar-collapse {
				margin: 0;
				.header-container {
					display: block;
				}
			}
		}
		.brand-wrapper {
			.app-logo {
				width: 35px;
				height: 40px;
			}
			.beta-disclaimer {
				font-size: 10px;
			}
		}
	}
}
