.distribution-table-container {
    margin: 0 1em 2em 0;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 20px 25px -15px $boxshadowgray;
    border: solid 1px $bordergrey;
    .distribution-table {
        color: $white;
        th {
            border-top: none;
        }
        td {
            vertical-align: middle;
        }
        .token-info-row {
            .token-info {
                display: flex;
                align-items: center;
                .token-icon {
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
    }
}
