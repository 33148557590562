.fund-info-card {
    background: $boxshadowgray;
    border-radius: 15px;
    box-shadow:
        0 0 15px $lightpink,
        0 0 15px $darkpuple;
    max-width: 450px;
    margin: 20px 0;
    &:hover {
        transform: scale(1.01);
        box-shadow:
            0 0 15px $lightpink,
            0 0 15px $magenta;
    }
    .fund-title {
        display: flex;
        font-weight: 600;
        align-items: center;
        text-decoration: none;
        margin-bottom: 20px;
        .fund-icon {
            width: 64px;
            height: 64px;
            border-radius: 50px;
            margin-right: 10px;
            box-shadow:
                0 0 5px $lightpink,
                0 0 5px $darkpuple;
        }
    }
    .fund-description {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: normal;
        font-size: 18px;
        font-weight: 1.2;
    }
    .card-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 0.5px solid $grey;
        .creator-icon {
            width: 48px;
            height: 48px;
            border-radius: 20px;
            margin-right: 10px;
        }
        .creator-name {
            font-size: 14px;
            color: $white;
        }
    }
}
@media #{$ipadLandscape} {
}
@media #{$ipadPortrait, $mobile} {
}
