.signup-form-container {
    margin: 20px auto;
    color: $darkgrey;
    .signup-title {
        text-align: center;
        margin-bottom: 30px;
    }
    .manual-signup {
        .button-wrapper {
            display: flex;
            justify-content: space-evenly;
        }
        .signup-btn {
            margin: auto;
        }
        .sso-signup {
            display: flex;
            flex-direction: column;
            margin: 30px auto;
            .btn {
                // width: 100%;
                margin: 12px auto;
                position: relative;
            }
            .google-btn {
                background-color: $white;
                color: $darkgrey;
                border: none;
                &:hover {
                    color: $grey;
                }
            }
            .icon-img {
                margin-right: 20px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        max-width: 50vw;
    }
    @include media-breakpoint-down(sm) {
        margin: 50px 10px;
        max-width: 100%;
        .sso-signup {
            .google-btn {
                max-width: 300px;
                .icon-img {
                    margin-right: 10px;
                }
            }
        }
    }
}
