.image-module-container {
    margin: 50px;
    .header {
        font-family: 'DMSans';
        font-size: 40px;
        line-height: 52px;
    }
    .image {
        margin: 92px 60px;
        max-width: 85vw;
    }
    @media #{$mobile} {
        .image{
            margin: 10px 20px;
            max-width: 75vw;
        }
    }
}