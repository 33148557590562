.hero-banner-container {
    .hero-banner {
        .image-col {
            .hero-image-desktop {
                width: 100%;
            }
            .hero-image-text {
                font-size: 40px;
                line-height: 52px;
                text-transform: capitalize;
                font-family: DMSans;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 20px;
                .near-logo {
                    max-height: 55px;
                }
            }
        }
        .content-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 50px;
            .content-header {
                margin-bottom: 40px;
                font-weight: 700;
                font-size: 60px;
                line-height: 91px;
            }
            .content-description {
                font-weight: 400;
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 30px;
            }
            .button-wrapper {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .gradient-btn {
                    align-items: center;
                }
            }
        }
        @media #{$mobile} {
            .image-col {
                .hero-image-desktop {
                    display: none;
                }
                .hero-image-mobile {
                    display: block;
                    margin: 0 auto;
                    max-width: 130px;
                    border-radius: 100px;
                    margin-bottom: 15px;
                    border-radius: 50%;
                    box-shadow:
                        0 0 15px $lightpink,
                        0 0 15px $darkpuple;
                }
            }
            .content-col {
                align-items: center;
                padding: 50px;
                .app-logo-desktop {
                    display: none;
                }
                .button-wrapper {
                    margin-top: 30px;
                    .link-btn {
                        font-size: 18px;
                        width: 250px;
                    }
                }
            }
        }
    }
}