.connect-exchange-container {
    margin: 50px auto;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 20px 25px -15px $boxshadowgray;
    border: solid 1px $bordergrey;
    background-color: $bggrey;
    .container-title {
        margin: 20px 10px;
    }
    .connect-exchange-form {
        .button-wrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 30px;
            .cancel-btn {
                background-color: $white;
                color: $darkgrey;
            }
        }
    }
    @media #{$mobile} {
        .connect-exchange-form .button-wrapper {
            flex-direction: column;
            .cancel-btn {
                margin-bottom: 20px;
            }
        }
    }
}
