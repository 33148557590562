.link-card-container {
    max-width: 207px;
    // height: 200px;
    border-radius: 20px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    background-color: $white;
    color: $darkgrey;
    padding: 38px 16px 62px 28px;
    position: relative;
    box-shadow: 0 20px 25px -15px rgba(104, 100, 100, 0.2);

    .link-btn {
        height: 46px;
        min-width: 44px;
        position: absolute;
        right: 6%;
        box-sizing: border-box;
        bottom: 8%;
        padding: 0;
        border-radius: 15px;
    }
}
