.features-banner-container {
    .features-banner {
        .image-col {
            .feature-image {
                width: 100%;
            }
        }
        .features-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 50px;
            .features-header {
                margin-bottom: 40px;
                font-weight: 700;
                font-size: 70px;
                line-height: 106px;
                margin-bottom: 27px;
            }
            .features-list {
                font-size: 40px;
                line-height: 52px;
                align-items: center;
                .feature-item {
                    margin-bottom: 25px;
                }
            }
        }
        @media #{$mobile} {
            
        }
    }
}