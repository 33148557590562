@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$green: #33b47e;
$white: #ffffff;
$midnightblue: #1b1555;
$blue: #1877f2;
$darkgrey: #333333;
$darkgrey2: #3a3a3a;
$grey: #708090;
$red: #fe554f;
$purple: #693be5;
$green2: #209b00;
$bordergrey: #dbe0e2fb;
$boxshadowgray: rgba(104, 100, 100, 0.2);
$boxshadowhover: rgba(104, 100, 100, 0.5);
$lightgreen: #54d59f;
$bggrey: #f5f5f5;


// new color palette
$lightpink: #b24bb2;
$hotpink: #9f149f;
$magenta: #740074;
$darkpuple: #400040;
$black: #000000;

$mobile: 'screen and (max-width: 767px)';
$ipadPortrait: '(min-width: 768px) and (max-width: 992px)';
$ipadLandscape: '(min-width: 993px) and (max-width: 1200px)';
$smallMobile: 'screen and (max-width: 576px)';
