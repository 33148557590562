.portfolio-preview-container {
    margin: 50px 15px;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 20px 25px -15px $boxshadowgray;
    border: solid 1px $bordergrey;
    padding: 30px 20px;
    background-color: $bggrey;
    .portfolio-description-wrapper {
        margin-bottom: 20px;
        .portfolio-icon {
            margin-right: 20px;
            width: 150px;
            border-radius: 20px;
        }
        .portfolio-description {
            padding-left: 10px;
            .title,
            .short-desc {
                margin-bottom: 10px;
            }
            .minimum-amt-text {
                display: inline-flex;
                .minimum-amt {
                    padding-left: 10px;
                }
            }
        }
    }
    .allocations-table-wrapper {
        margin: 0 50px 20px;
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 20px 25px -15px $boxshadowgray;
        .table-header {
            padding: 10px;
            vertical-align: baseline;
        }
        td {
            vertical-align: middle;
        }
        .coin-details {
            display: flex;
            align-items: center;
            .coin-icon {
                margin-right: 10px;
                width: 31px;
            }
        }
    }
    .button-wrapper {
        .edit-btn,
        .publish-btn {
            margin: 0 20px;
        }
    }
}
